import React from 'react';
import { FaBook } from 'react-icons/fa';
import './Universal_Blog.css';

export default function web_article() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="card-rag">
      <button
          className="btn btn-primary btn-sm move-top-btn"
          onClick={scrollToTop}
        >Scroll Top</button>
      <div className="Book_icon">
        <FaBook style={{ height: '70px', width: '70px' }} />
      </div>
      <div className="card-body_rag">
        <blockquote className="blockquote mb-0">
          <h1>
            Building a Basic Static WebPage
        </h1>
        <br />
        <h2>Starting with tags:</h2>
        <br />
        <p>
            Example for a h1 tag
        </p>
        <br />
        &lt;h1&gt;<h1>This is an h1 tag</h1>&lt;/h1&gt;
        <br />
        <br />
        <p>Example for a h2 tag</p>
        &lt;h2&gt;<h2>This is an h2 tag</h2>&lt;/h2&gt;
        <br />
        <br />
        <p>Similarly for other tags &lt;h3&gt; and so on till  &lt;h6&gt;</p>
        &lt;h3&gt;....&lt;/h3&gt;
        <br />
        &lt;h4&gt;....&lt;/h4&gt;
        <br />
        &lt;h5&gt;....&lt;/h5&gt;
        <br />
        &lt;h6&gt;....&lt;/h6&gt;
        <br />
        <br />
        <br />
        <p><b>Sample Website image</b></p>
        <br />
        <img src={`${process.env.PUBLIC_URL}/Blog_pics/article/sample_image .png`} alt="App Development"  style={{ width: '35rem', height: '20rem' }} />
        </blockquote>
      </div>
    </div>
  );
}
