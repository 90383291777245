import React from "react";
import { FaBook } from "react-icons/fa";
import "./Universal_Blog.css";

export default function Devops() {
  const scrollToTop  = ()=>{
    window.scrollTo({top:0,behavior:'smooth'})
  }
  return (
    <div className="card-rag">
      <button
          className="btn btn-primary btn-sm move-top-btn"
          onClick={scrollToTop}
        >Scroll Top</button>
      <div className="Book_icon">
        <FaBook style={{ height: "70px", width: "70px" }} />
      </div>
      <div className="card-body_rag">
        <blockquote className="blockquote mb-0">
          <h1>Introduction to DevOps</h1>
          <br />
          <br />
          <p>
            Application life cycle management involves going through many
            iterations for the developers, testers, deployment teams and
            maintenance engineers. They need maintain multiple environments like
            dev, prod, testing, staging and prod. Any change in configuration
            should be brought into all the environments that may be
            dependencies, libraries, different versions of the applications so
            on. All these for a simple project seem simple but if you increase
            complexity of the project and number of iterations increase with lot
            of steps then role of an automation can be better appreciated. Here,
            the DevOps engineering does the orchestration role to smoothen this
            process fault free.
          </p>
          <br />
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/devops.webp`}
            alt="none"
          />
          <br />
          <br />
          <p>
            DevOps is a short form of development and operations. They will take
            care of both developmental work as well as operational work.
            Development work involves code creation and maintenance (branching
            etc..), code building, versioning of built code, testing different
            version, deploying those versions that passed the test into
            different environments. Finally, maintenance involves monitoring the
            application for bugs, fixes, latencies etc. Thus, the loop starts
            again for code improvement to fix bugs found in monitoring. let us
            take our discussion on these lines and try to understand DevOps.
          </p>
          <br />
          <h3>Project planning:</h3>
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/planning.png`}
            alt="none"
          />
          <br />
          <p>
            Businesses comes with their data processing requirement. During
            planning the experts understand the requirement and breaks down them
            into user stories and stories into technical requirement and
            specifications. Once that is done it is taken to client for his
            consent and feedback.
          </p>
          <br />
          <h3>Code management:</h3>
          <br />
          <p>
            Accepted tasks are to be brought into code. Number of teams start
            working in building code and committing them to a common repository.
            Here GitHub is widely got famous in code management. DevOps engineer
            will administer over repos, creates branches, approves pull requests
            and approves deployments into different environments. Branch
            management covers creation of master, dev, hotfix and release
            branches.
          </p>
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/comited.png`}
            alt="none"
          />
          <br />
          <h3>Code building:</h3>
          <br />
          <p>
            When developer codes the logic he uses predefined libraries to
            customize to the requirement. To execute those libraries, we need
            certain modules to be downloaded, dependencies to be present along
            with the code. This packing of these along with code is called as
            code building. This works as a .exe, .tar, .war, .jar files based on
            applications that built them.
          </p>
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/building.png`}
            alt="none"
          />
          <br />
          <h3>Versioning of built code:</h3>
          <br />
          <p>
            The code build files are not deployed to higher environments without
            testing. Before testing, to maintain sanity and trackability of the
            different build files. It will be versioned out. We have many
            artifact versioning tools like Nginx, Jfrog.
          </p>
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/artifact.png`}
            alt="none"
          />
          <br />
          <h3>Testing versions:</h3>
          <br />
          <p>
            The different versions of the artifact will undergo unit testing,
            functional testing and load testing etc. This will give us on the
            picture of performance of the application under different conditions
            along with desired behavior is being able to achieve. There are
            automatic testing methods as well as manual testing methods to
            understand the code that is action. DevOps engineer will deploy
            artifact in to testing environment and at times he may also involve
            in testing as well.
          </p>
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/testing.png`}
            alt="none"
          />
          <br />
          <h3>Deployment:</h3>
          <br />
          <p>
            Deployment is deploying the application into different setups based
            on the type of application you have built. It can be server
            deployment (IAAS-Infrastructure as a service), PAAS (platform as a
            service) deployment, FAAS (function as a service) deployment and
            finally software as a service. Depending on where you deploying the
            terminology and process will change. Server deployment (IAAS): Here
            you can simply create an VM (virtual machine) and load necessary
            dependencies for the application. Once environment is setup you can
            deploy to the server
          </p>
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/deploy.png`}
            alt="none"
          />
          <br />
          <p>
            Docker images deployment (PAAS): Docker images are created from the
            code and those images are sent into image repositories (as we did
            for artifactory repos) and they are sent to ECS clusters for
            instance. It is PAAS deployment.
          </p>
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/ecs.png`}
            alt="none"
          />
          <br />
          <p>
            FAAS deployment: The code is deployed into Azure functions or AWS
            lambda for the updated code.
          </p>
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/azure.png`}
            alt="none"
          />
          <br />
          <p>
            SAAS deployment: It is mostly like an on-premise deployment where
            you take care of everything for application running. This deployment
            is another possibility.
          </p>
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/premises.png`}
            alt="none"
          />
          <br />
          <h3>Monitoring the application:</h3>
          <br />
          <p>
            Application monitoring involves timely reaction to the critical
            alerts and creating tickets for bug fixes. It involves collection
            metrics, logs and visualizing them on the dashboard. Alerting the
            teams based on alerts from those calibrations. The DevOps engineer
            or an SRE will take care of these activities.
          </p>
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/monitoring.png`}
            alt="none"
          />
          <br />
          <h3>Environment configuration management:</h3>
          <br />
          <p>
            If it is not a Docker file, then application environment must be
            consistent. This also requires effort to automate. There are
            configuration management tools like Ansible, Chef, terraform which
            can deploy consistent application environments through their codes.
            DevOps engineer will take this responsibility of creating
            configuration as a code and automate the application environment
            consistency.
          </p>
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/config.png`}
            alt="none"
          />
          <br />
          <h3>Infrastructure as a code:</h3>
          <br />
          <p>
            In the era of cloud computing, it is essential that we deploy
            smoothly along with maintaining costs. DevOps engineer brings
            infrastructure in the form of code and applies when needed and
            destroy the when the environment is not in need. For instance, you
            don’t have to keep testing environment unless it is needed for
            testing. This will save costs.
          </p>
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/iac.png`}
            alt="none"
          />
          <br />
          <h3>CI/CD orchestration: </h3>
          <br />
          <p>
            This entire process we discussed should be organized as a sequence
            of steps with sequences of actions involved in them. For instance,
            code building requires cloning code, executing build command and
            storing the artifact in a directory. This process may require single
            command or sequence of multiple commands. So, the orchestration
            tools like Jenkins, team city etc. are used to execute these steps
            to implement the CI/CD- continuous integration and continuous
            deployment process.
          </p>
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/Blog_pics/Devops/ci.png`}
            alt="none"
          />
          <br />
          <br />
          <iframe
            className="iframe"
            width="600"
            height="400"
            src="https://www.youtube.com/embed/Xrgk023l4lI"
            title="Intro to DevOps"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <br />
          <br />
          <h3>Conclusion:</h3>
          <br />
          <p>
            DevOps is a process that helps in implementing sequences of steps
            that are required for application building through automation and
            the engineer will build that automation process and looks after
            issues and fixes whenever needed.
          </p>
          <br />
          <br />
          <br />
        </blockquote>
      </div>
    </div>
  );
}
